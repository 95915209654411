import React from 'react';
import { css, useTheme } from '@emotion/react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
// const linkResolver = require('@/prismic/resolver')

import { htmlSerializer } from './htmlSerializer';
import useLinkResolver from './useResolver';

interface PrismicComponentProps {
  content: RichTextBlock[];
}
const PrismicComponent: React.FC<PrismicComponentProps> = ({ content, ...props }) => {
  const theme = useTheme();
  const linkResolver = useLinkResolver();
  return (
    <div
      css={css`
        b,
        strong {
          font-weight: 700;
        }
        ​ em {
          font-style: italic;
        }
        h1, h2, h3, h4 {
          margin-top: ${theme.spacing(1)};
          margin-bottom: ${theme.spacing(3)};
        }
        p {
          margin-bottom: ${theme.spacing(1)};
        }
        a {
          color: ${theme.color.primary.main};
          &:hover {
            text-decoration: underline;
          }
        }
      `}
      {...props}
    >
      <RichText
        render={content}
        //@ts-ignore
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializer}
      />
    </div>
  );
};

export default PrismicComponent;

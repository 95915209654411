import React from 'react';
import styled from '@emotion/styled';

import { Img, Typography, Link } from '@/components/atoms';
import mq from '@/styles/mq';
import ImageDataType from '@/types/data/image';


interface ProductThumbnailProps {
    uid: string;
    title: string;
    image: ImageDataType;
}


const ProductThumbnailRoot = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s;
    width: 100%;
    height: 400px;
    margin-right: ${({ theme }) => theme.spacing(0)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(1)};
    ${mq('xxs')} {
        width: 100%;
        margin-right: ${({ theme }) => theme.spacing(1)};
        margin-bottom: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(1)};
    }
    ${mq('xs')} {
        width: 45%;
        height: auto;
        margin-right: ${({ theme }) => theme.spacing(1)};
        margin-bottom: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(1)};
    }
    ${mq('sm')} {
        width: 30%;
        margin-right: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
    }
    ${mq('md')} {
        width: 31%;
        margin-right: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
    }
    ${mq('lg')} {
        width: 23%;
        margin-right: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
    }
    ${mq('xl')} {
        width: 18%;
        margin-right: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(2)};
    }
    &:hover {
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        -webkit-box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        -moz-box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }
`;

const ProductImageContainer = styled.div`
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProductImage = styled(Img)`
    width: 100%;
    height: auto;
`;

const ProductTitle = styled(Typography)`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)};
    text-align: center;
    color: ${({ theme }) => theme.color.black.main};

`;


export const ProductThumbnail = ({ uid, title, image, ...props }: ProductThumbnailProps): JSX.Element => {

    return (
        <ProductThumbnailRoot to={`${uid}`} {...props}>
            <ProductImageContainer>
                <ProductImage image={image} />
            </ProductImageContainer>
            <ProductTitle variant="textMd" as="p">
                {title}
            </ProductTitle>
        </ProductThumbnailRoot>
    );
};


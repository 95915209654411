import React, { useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
// import * as cookies from 'js-cookie';
import * as ReactGA from 'react-ga';

import mq from '@/styles/mq';
import { Typography, Link, Button } from '@/components/atoms';


interface CookiesProps {
    lang: string;
}

interface BannerLangProps {
    [key: string]: {
        [key: string]: string;
    }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  bottom: 15px;
  left: 15px;
  padding: 1rem 2rem;
  position: fixed;
  z-index: 1500;
  background-color: ${({ theme }) => theme.color.white.light};
  transform: translateY(0%);
  border-radius: 10px;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  ${mq('sm')} {
      flex-direction: row;
      bottom: 25px;
      left: 25px;
  }
`;

const CookiesContent = styled(Typography)`
    text-align: center;
    ${mq('xs')} {
        text-align: left;
    }
`;

const ButtonsContainer = styled.div`
    /* width: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(2)};
    ${mq('xs')} {
        flex-direction: row;
    }
    ${mq('sm')} {
        margin-top: ${({ theme }) => theme.spacing(0)};
        margin-left: ${({ theme }) => theme.spacing(2)};
    }
`;

const AcceptButton = styled(Button)`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        margin-top: ${({ theme }) => theme.spacing(0)};
        margin-left: ${({ theme }) => theme.spacing(2)};
    }
`;

const DeclineButton = styled(Button)`
    width: 100%;
`;

const bannerLang: BannerLangProps = {
    'fr-be': {
        p: 'Ce site utilise des cookies de mesure,',
        a: 'en savoir plus.',
        buttonAccept: 'ACCEPTER',
        buttonDecline: 'REFUSER'
    },
    'en-gb': {
        p: 'This site uses measurement cookies,',
        a: 'know more.',
        buttonAccept: 'ACCEPT',
        buttonDecline: 'DECLINE'
    },
    'nl-be': {
        p: 'Deze site maakt gebruik van meetcookies,',
        a: 'weet meer.',
        buttonAccept: 'ACCEPTEREN',
        buttonDecline: 'AFWIJZEN'
    }
}



export const CookiesBanner = ({ lang }: CookiesProps): JSX.Element => {
    const { color } = useTheme();
    const [open, setOpen] = useState<boolean>(true);
    const { p, a, buttonAccept, buttonDecline } = bannerLang[lang];

    const handleConfirmCookie = () => {
        if (process.env.GATSBY_GOOGLE_ANALYTICS_ID) {
            if (process.env.NODE_ENV === 'production') {
                Cookies.set('isConsent', 'true', {
                    expires: 365,
                });
                ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
                    gaOptions: {
                        sampleRate: 100,
                        siteSpeedSampleRate: 100,
                    },
                });
                //@ts-ignore
                Cookies.set('gatsby-gdpr-google-analytics', true);
                setOpen(false);
            }
        }
    };
    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
        Cookies.set('isConsent', 'false', {
            expires: 365,
        });
        //@ts-ignore
        Cookies.set('gatsby-gdpr-google-analytics', false);
        setOpen(false);
    };

    const cookie = Cookies.get('isConsent');


    useEffect(() => {

        if (cookie === "true") {
            setOpen(false);
            handleConfirmCookie();
        } else if(cookie === "false") {
            setOpen(false);
            handleDeclineCookie();
        } else {
            setOpen(true);
        }
    }, [cookie]);
    
    return (
        <Root css={!open && css`transform: translateY(150%); transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);`}>
            <CookiesContent variant="textSm">
                {p}{' '}
                <Link css={css`color: ${color.primary.main};}`} to={`/${lang}/cookies`}>
                    {a}
                </Link>
            </CookiesContent>
            <ButtonsContainer>
                <DeclineButton color="primary" onClick={handleDeclineCookie}>
                    {buttonDecline}
                </DeclineButton>
                <AcceptButton color="primary" onClick={handleConfirmCookie}>
                    {buttonAccept}
                </AcceptButton>
            </ButtonsContainer>
        </Root>
    );
};


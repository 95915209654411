import React, { ForwardedRef, HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { Label } from '@/components/atoms/Form/Label';

export type InputType = 'text' | 'email' | 'tel';

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  name: string;
  placeholder?: string;
  /**
   Set a label associated with the input
   */
  label?: string;
  required?: boolean;
  star?: boolean;
  /**
   The available options
   */
  children?: React.ReactNode;
}

const SelectRoot = styled('select')`
  padding: ${({ theme }) => theme.spacing(1.5)};
  border: 0.5px solid ${({ theme }) => theme.color.textPrimary.light};;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white.light};
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.color.textSecondary.main};
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

const SelectLabel = styled(Label)`
  color: ${({ theme }) => theme.color.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const Star = styled.span`
  color: ${({ theme }) => theme.color.error.main}; 
`;

/**
 <h3>Display a select input</h3>
 **/
export const Select = React.forwardRef(
  (
    { label, name, children, star = false, ...props }: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ): JSX.Element => {
    return (
      <>
        {label && <SelectLabel htmlFor={name}>{label} {star && <Star>*</Star>}</SelectLabel>}
        <SelectRoot ref={ref} id={name} name={name} {...props}>
          {children}
        </SelectRoot>
      </>
    );
  },
);

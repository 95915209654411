import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import mq from '@/styles/mq';
import setColor from '@/utils/setColor';

import UtilityProps from '@/types/style/utility';
import ColorType from '@/types/style/color';

type TypoType =
  | `h1`
  | `h2`
  | `h3`
  | `h4`
  | `h5`
  | `h6`
  | `textLg`
  | `textMd`
  | `textSm`
  | `textXs`
  | `textXxs`;

export interface TypographyProps extends Omit<UtilityProps, 'size'> {
  children: React.ReactNode;
  /**
   The style which should be applied to a text
   */
  variant?: TypoType;
  /**
   The color of the text
   */
  color?: ColorType;
  /**
   Change the component used when displaying the typography
   */
  as?: React.ElementType;
}

const setTypoStyle = (variant?: TypoType) => {
  switch (variant) {
    case 'h1':
      return css`
        font-size: 3.3rem;
        font-weight: 800;

        ${mq('lg')} {
          font-size: 4.5rem;
        }
      `;
    case 'h2':
      return css`
        font-size: 3.1rem;
        font-weight: 800;

        ${mq('lg')} {
          font-size: 3.5rem;
        }
      `;
    case 'h3':
      return css`
        font-size: 2.6rem;
        font-weight: 800;

        ${mq('lg')} {
          font-size: 3rem;
        }
      `;
    case 'h4':
      return css`
        font-size: 2rem;
        font-weight: 800;

        ${mq('lg')} {
          font-size: 2.3rem;
        }
      `;  
    case 'textLg':
      return css`
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 1.5;

        ${mq('lg')} {
          font-size: 2.3rem;
        }
      `;
    case 'textMd':
      return css`
        font-size: 1.9rem;
        font-weight: 500;
        line-height: 1.5;

        ${mq('lg')} {
          font-size: 2.1rem;
        }
      `;
    case 'textSm':
      return css`
        font-size: 1.5rem;
        line-height: 1.5;
        font-weight: 500;

        ${mq('lg')} {
          font-size: 1.6rem;
        }
      `;
    case 'textXs':
      return css`
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 500;

        ${mq('lg')} {
          font-size: 1.4rem;
        }
      `;
    case 'textXxs':
      return css`
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 500;

        ${mq('lg')} {
          font-size: 1.3rem;
        }
      `; 
    default:
      return css`
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
      `;
  }
};

const TypographyRoot = styled.p<TypographyProps>`
  color: ${({ color = 'textPrimary' }) => setColor(color)};
  ${({ variant }) => setTypoStyle(variant)};
`;

/**
 <h3>Wrapper for all typography needs</h3>
 **/
export const Typography = ({
  children,
  as = 'div',
  ...rest
}: TypographyProps): JSX.Element => {
  return (
    <TypographyRoot as={as} {...rest}>
      {children}
    </TypographyRoot>
  );
};

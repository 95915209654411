import React from 'react';
import { Elements } from 'prismic-reactjs';
import { css, useTheme } from '@emotion/react';
import { Link } from '@/components/atoms';
import { Typography } from '@/components/atoms'; 
// const linkResolver = require('@/prismic/resolver');
import useLinkResolver from './useResolver';


// -- Function to add unique key to props
const propsWithUniqueKey = function (props: object, key: number) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
// @ts-ignore
export const htmlSerializer = function (type, element, content, children, key) {
  var props = {};
  const theme = useTheme();
  const linkResolver = useLinkResolver();
  switch (type) {
    
    // Add a class to paragraph elements

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' };
      return React.createElement('img', propsWithUniqueKey(props, key));

    // Add a class to hyperlinks
    case Elements.hyperlink:

      if (element.data.link_type === 'Document') {
        return (
          <Link key={element.data.id} to={linkResolver(element.data)} >
            {content}
          </Link>
        );
        } else {
          return (
            <a key={element.data.id} href={element.data.url} target="_blank">
              {content}
            </a>
          )
        }
    // Return null to stick with the default behavior
    case Elements.heading1:
      return (
        <Typography
          as="h1"
          variant="h1"
          css={css`
            color: ${theme.color.primary.main};
          `}
        >
          {children}
        </Typography>
      );
    case Elements.heading2:
      return (
        <Typography
          as="h2"
          variant="h2"
          css={css`color: ${theme.color.primary.dark};`}
        >
          {children}
        </Typography>
      );
    case Elements.heading3:
      return (
        <Typography
          as="h3"
          variant="h3"
          css={css`color: ${theme.color.primary.dark};`}
        >
          {children}
        </Typography>
      );
    case Elements.heading4:
      return (
        <Typography
          as="h4"
          variant="h4"
          css={css`color: ${theme.color.primary.dark};`}
        >
          {children}
        </Typography>
      );
    case Elements.paragraph:
      return (
        <Typography
          as="p"
          variant="textSm"
          css={css`
            color: ${theme.color.black.main};
          `}
        >
          {children}
        </Typography>
      );
    case Elements.listItem: 
        return (
          <li css={css`list-style: inside; margin-left: ${theme.spacing(0)}; font-weight: 500; color: ${theme.color.black.main};`}>
            {children}
          </li>
        )
    case Elements.oListItem:
      return ( 
        <li css={css`margin: ${theme.spacing(2)}; font-weight: 500; color: ${theme.color.black.main};`}>
          {children}
        </li>
      )

    default:
      return null;
  }
};

//export default htmlSerializer;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import mq from '@/styles/mq';
import { Typography, Box, Link, Img } from '@/components/atoms';
import { SelectLanguage } from '@/components/molecules';

import { LangLinkType } from '@/types/data/lang';

interface DesktopNavProps {
    links?: {
        label: string;
        path: string;
    }[];
    isHome?: boolean;
    selectLang: {
        lang: string;
        uid: string;
        alternate_languages: {
            lang: string;
            uid: string;
        }[] | undefined;
    };
}



const DesktopNavRoot = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  background-color: rgba(250, 250, 250, 0.8);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  ${mq('md')} {
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('xl')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;


const NavLink = styled(Link)`
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black.main};
    /* margin-right: ${({ theme }) => theme.spacing(2)}; */
    ${mq('lg')} {
      font-size: 1.6rem;
        /* margin-right: ${({ theme }) => theme.spacing(5)}; */
    }
    ${mq('xl')} {
        /* margin-right: ${({ theme }) => theme.spacing(6)}; */
    }
`;

const LogoLink = styled(Link)``;

const LogoImg = styled(Img)`
  width: 70px;
  height: 70px;
  ${mq('sm')} {
    width: 80px;
    height: 80px;
  }
  ${mq('md')} {
    width: 80px;
    height: 80px;
  }
  ${mq('xl')} {
    width: 100px;
    height: 100px;
  }
`;

const Links: LangLinkType = {
  "fr-be": [
    {
      label: 'Accueil',
      path: '/fr-be'
    },
    {
      label: 'Catalogue',
      path: '/fr-be/catalogue'
    },
    {
      label: 'Dépannage Réparation',
      path: '/fr-be/depannage'
    },
    {
      label: 'Entretien',
      path: '/fr-be/entretien'
    },
    {
      label: 'Galerie',
      path: '/fr-be/galerie'
    },
    {
      label: 'À propos',
      path: '/fr-be/a-propos'
    },
    {
      label: 'Devis',
      path: '/fr-be/devis'
    },
    {
      label: 'Contact',
      path: '/fr-be/contact'
    },
  ],
  "en-gb": [
    {
      label: 'Home',
      path: '/en-gb'
    },
    {
      label: 'Catalog',
      path: '/en-gb/catalog'
    },
    {
      label: 'Troubleshooting',
      path: '/en-gb/troubleshooting'
    },
    {
      label: 'Maintenance',
      path: '/en-gb/maintenance'
    },
    {
      label: 'Gallery',
      path: '/en-gb/gallery'
    },
    {
      label: 'About',
      path: '/en-gb/about'
    },
    {
      label: 'Quotes',
      path: '/en-gb/quotes'
    },
    {
      label: 'Contact',
      path: '/en-gb/contact'
    },
  ],
  "nl-be": [
    {
      label: 'Ontvangst',
      path: '/nl-be'
    },
    {
      label: 'Catalogus',
      path: '/nl-be/catalogus'
    },
    {
      label: 'Probleemoplossen',
      path: '/nl-be/probleemoplossen'
    },
    {
      label: 'Onderhoud',
      path: '/nl-be/onderhoud'
    },
    {
      label: 'Galerij',
      path: '/nl-be/galerij'
    },
    {
      label: 'Over ons',
      path: '/nl-be/over-ons'
    },
    {
      label: 'Offertes',
      path: '/nl-be/offertes'
    },
    {
      label: 'Contact',
      path: '/nl-be/contact'
    },
  ]
}


export const DesktopNav = ({ links, selectLang, isHome }: DesktopNavProps): JSX.Element => {
    const { color } = useTheme();
    const logo = useStaticQuery(query);
    const { lang } = selectLang;
    const navlinks = Links[lang];
    return (
        <DesktopNavRoot>
            <LogoLink to={`/${lang}`}>
                <LogoImg image={logo?.logoDesktop?.childImageSharp} />
            </LogoLink>
              {navlinks?.map(link => (
                <NavLink to={`${link?.path}`} activeStyle={{ color: `${color.primary.main}` }}>
                    {link?.label}
                </NavLink>
              ))}
            <SelectLanguage selectLang={selectLang} isHome={isHome} />
        </DesktopNavRoot>
    );
};


const query = graphql`
  query LogoDesktop {
  logoDesktop: file(relativePath: {eq: "Topservices24-Logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
`;
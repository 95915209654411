import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import setSize from '@/utils/setSize';

import SizeType from '@/types/style/size';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  /**
   Change the maxWidth of the container

   String that match the theme breakpoints or a number representing the screen size in px
   */
  maxWidth?: SizeType | number;
  children: React.ReactNode;
}

const ContainerRoot = styled.div<ContainerProps>`
  margin: auto;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'string' ? setSize(maxWidth) : maxWidth}px;
`;

/**
 <h3>Create a container with a max-width and some padding to wrap other components</h3>
 **/
export const Container = ({
  children,
  maxWidth = 'lg',
  ...rest
}: ContainerProps): JSX.Element => {
  return (
    <ContainerRoot maxWidth={maxWidth} {...rest}>
      {children}
    </ContainerRoot>
  );
};

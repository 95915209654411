// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/AboutTemplate.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-catalogue-template-tsx": () => import("./../../../src/templates/CatalogueTemplate.tsx" /* webpackChunkName: "component---src-templates-catalogue-template-tsx" */),
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/CategoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/ContactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-cookies-template-tsx": () => import("./../../../src/templates/CookiesTemplate.tsx" /* webpackChunkName: "component---src-templates-cookies-template-tsx" */),
  "component---src-templates-depannage-template-tsx": () => import("./../../../src/templates/DepannageTemplate.tsx" /* webpackChunkName: "component---src-templates-depannage-template-tsx" */),
  "component---src-templates-devis-template-tsx": () => import("./../../../src/templates/DevisTemplate.tsx" /* webpackChunkName: "component---src-templates-devis-template-tsx" */),
  "component---src-templates-entretien-template-tsx": () => import("./../../../src/templates/EntretienTemplate.tsx" /* webpackChunkName: "component---src-templates-entretien-template-tsx" */),
  "component---src-templates-galerie-template-tsx": () => import("./../../../src/templates/GalerieTemplate.tsx" /* webpackChunkName: "component---src-templates-galerie-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-mentions-template-tsx": () => import("./../../../src/templates/MentionsTemplate.tsx" /* webpackChunkName: "component---src-templates-mentions-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}


import '@emotion/react';

import ColorType from '@/types/style/color';
import SizeType from '@/types/style/size';
import zIndexType from '@/types/style/zIndex';

interface ColorPalette {
  light: string;
  main: string;
  dark: string;
}

declare module '@emotion/react' {
  export interface Theme {
    color: {
      [key in ColorType]: ColorPalette;
    };
    breakpoints: {
      [key in SizeType]: number;
    };
    grid: {
      columns: number;
    };
    spacingBase: number;
    spacing: (multiplier: number) => string;
    zIndex: {
      [key in zIndexType]: number;
    };
  }
}

export const spacingBase = 10;

export const color = {
  primary: {
    light: `hsla(197, 74%, 55%, 1)`,
    main: `hsla(197, 74%, 36%, 1)`,
    dark: `hsla(197, 94%, 27%, 1)`,
  },
  secondary: {
    light: `hsla(192, 34%, 62%, 1)`,
    main: `hsla(192, 34%, 52%, 1)`,
    dark: `hsla(192, 34%, 32%, 1)`,
  },
  textPrimary: {
    // light: `hsl(214, 31%, 26%)`,
    // main: `hsl(214, 31%, 26%)`,
    // dark: `hsl(214, 31%, 16%)`,
    light: `hsla(0, 0%, 70%, 1)`,
    main: `hsla(0, 0%, 50%, 1)`,
    dark: `hsla(0, 0%, 30%, 1)`,
  },
  textSecondary: {
    light: `hsl(0, 0%, 70%)`,
    main: `hsl(0, 0%, 50%)`,
    dark: `hsl(0, 0%, 30%)`,
  },
  success: {
    light: `hsl(145, 63%, 50%)`,
    main: `hsl(145, 63%, 50%)`,
    dark: `hsl(145, 63%, 50%)`,
  },
  warning: {
    light: `hsl(46, 100%, 60%)`,
    main: `hsl(46, 100%, 60%)`,
    dark: `hsl(46, 100%, 60%)`,
  },
  error: {
    light: `hsl(0, 100%, 70%)`,
    main: `hsl(0, 100%, 70%)`,
    dark: `hsl(0, 100%, 70%)`,
  },
  black: {
    light: `hsl(0, 0%, 6%)`,
    main: `hsl(0, 0%, 3%)`,
    dark: `hsl(0, 0%, 0%)`,
  },
  white: {
    light: `hsl(0, 0%, 100%)`,
    main: `hsl(0, 0%, 90%)`,
    dark: `hsl(0, 0%, 80%)`,
  },
};

export const breakpoints = {
  xxs: 375,
  xs: 576,
  sm: 768,
  md: 980,
  lg: 1280,
  xl: 1440,
  xxl: 1980,
};

export const grid = {
  columns: 12,
};

export const spacing = (multiplier: number): string => `${10 * multiplier}px`;

export const zIndex = {
  default: 1,
  background: 100,
  overlay: 200,
  content: 300,
  tooltip: 400,
  dropdown: 500,
  fixed: 600,
  nav: 700,
  actions: 800,
  modal: 1000,
};

const theme = {
  color,
  breakpoints,
  grid,
  spacingBase,
  spacing,
  zIndex,
};

export default theme;

import React, { ForwardedRef, HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { Label } from '@/components/atoms/Form/Label';

export type InputType = 'text' | 'email' | 'tel';

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  /**
  Set a label associated with the input
   */
  label?: string;
  required?: boolean;
  star?: boolean;
  /**
   The type of the input
   */
  type?: InputType;
}

const InputRoot = styled('input')`
  padding: ${({ theme }) => theme.spacing(1)};
  border: 0;
  border-bottom: 0.5px solid ${({ theme }) => theme.color.textPrimary.light};
  background-color: transparent;
  width: 100%;
  outline: none !important;
  box-shadow: none;
  transition: .3s;
  color: ${({ theme }) => theme.color.black.main};
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-fill-color: ${({ theme }) => theme.color.black.main} !important;
  &::placeholder {
    color: ${({ theme }) => theme.color.black.main};
    font-size: 1.4rem;
    font-weight: 500;
  }
  &:focus {
    border-bottom: 0.5px solid ${({ theme }) => theme.color.primary.main};
  }
`;

const InputLabel = styled(Label)`
  color: ${({ theme }) => theme.color.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Star = styled.span`
  color: ${({ theme }) => theme.color.error.main}; 
`;
/**
 <h3>Display an input</h3>
 **/
export const Input = React.forwardRef(
  (
    { label, type = 'text', name, star = false, ...props }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <>
        {label && <InputLabel htmlFor={name}>{label} {star && <Star>*</Star>}</InputLabel>}
        <InputRoot type={type} ref={ref} id={name} name={name} {...props} />
      </>
    );
  },
);


import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';

import { Typography, Img } from '@/components/atoms';

import { PrismicBanner } from '@/domain/PrismicBanner';

import mq from '@/styles/mq';

import 'swiper/css';
import 'swiper/css/effect-fade';

SwiperCore.use([Autoplay, EffectFade]);

interface BannerProps {
    lang: string;
}

interface StaticQuery {
    allPrismicBanner: {
        edges: {
            node: PrismicBanner;
        }[];
    }
}

const BannerRoot = styled.section`
    .swiper-container{
        z-index: 0 !important;
    }
    position: relative;
`;

const SliderContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SliderImage = styled(Img)`
    width: 100%;
    height: 240px;
    ${mq('xs')} {
        width: 100%;
        height: 270px;
    }
    ${mq('sm')} {
        width: 100%;
        height: 300px;
    }
    ${mq('md')} {
        width: 100%;
        height: auto;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.4);
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-bottom: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-bottom: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
    }
`;
const SliderText = styled(Typography)`
    color: ${({ theme }) => theme.color.white.main};
`;
const SliderNum = styled(Typography)`
    color: ${({ theme }) => theme.color.primary.light};
`;

export const Banner = ({ lang }: BannerProps): JSX.Element => {
    const data = useStaticQuery<StaticQuery>(query);
    const [{ node }] = data?.allPrismicBanner?.edges.filter(data => data?.node?.lang === lang)

    return (
        <BannerRoot>
            <Swiper
                autoplay={{ delay: 5000 }}
                loop
            
                slidesPerView={1}
                effect="fade"
            >
                <SliderContainer>
                    {node?.data?.slider_images?.map(image => (
                        <SwiperSlide>
                            <SliderImage image={image?.image} />
                        </SwiperSlide>
                    ))}
                </SliderContainer>
            </Swiper>
            <Content>
                <SliderText variant="textLg" as="p">
                    {node?.data?.slider_text_1?.text}
                </SliderText>
                <SliderNum variant="textLg" as="p">
                    {node?.data?.slider_num?.text}
                </SliderNum>
                <SliderText variant="textLg" as="p">
                    {node?.data?.slider_text_3?.text}
                </SliderText>
            </Content>
        </BannerRoot>
    );
};


const query = graphql`
query Banner {
  allPrismicBanner {
    edges {
      node {
        lang
        data {
          slider_images {
            image {
              gatsbyImageData
              alt
            }
          }
          slider_text_1 {
            text
          }
          slider_num {
            text
          }
          slider_text_3 {
            text
          }
        }
      }
    }
  }
}

`;
import React from 'react';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';

import PrismicComponent from '@/prismic/prismicComponent';

import mq from '@/styles/mq';

interface ContentProps {
    content: RichTextBlock[];
}


const ContentRoot = styled.section`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;


export const Content = ({ content }: ContentProps): JSX.Element => {
    
    return (
        <ContentRoot>
            <PrismicComponent content={content} />
        </ContentRoot>
    );
};


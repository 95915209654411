import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css, useTheme, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import mq from '@/styles/mq';
import { Typography, Link, Img, Hamburger, Button } from '@/components/atoms';
import { SelectLanguage } from '@/components/molecules';

import { LangLinkType } from '@/types/data/lang';

interface MobileNavProps {
    links?: {
        label: string;
        path: string;
    }[];
    isHome?: boolean;
    selectLang: {
        lang: string;
        uid: string;
        alternate_languages: {
            lang: string;
            uid: string;
        }[] | undefined;
    };
}

const MobileNavRoot = styled.div`
  height: 100%;
  display: block;
  ${mq('md')} {
    display: none;
  }
`;
const NavMenu = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transform: translateX(150vw);
  background-color: ${({ theme }) => theme.color.white.light};
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: .5s;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  ${mq('xs')} {
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;
const HamburgerStyled = styled(Hamburger)`
  border-radius: 50%;
`;
const openedMenu = css`
  transform: translateX(0);
`;
const openFab = css``;
const closeFab = (theme: Theme) => css`
  border-color: ${theme.color.white.main};
`;
const NavTrigger = styled(Button)`
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: ${({ theme }) => theme.zIndex.actions};
  ${HamburgerStyled} > span {
    background-color: ${({ color, theme }) =>
        color === 'primary'
            ? theme.color.white.main
            : theme.color.textPrimary.main};
  }

  &:hover ${HamburgerStyled} > span {
    background-color: ${({ color, theme }) =>
        color === 'primary'
            ? theme.color.textPrimary.main
            : theme.color.white.main};
  }
`;

const LogoLink = styled(Link)`
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const LinksContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(4)};
`;

const NavLink = styled(Link)`
    transition: .3s;
    color: ${({ theme }) => theme.color.textPrimary.dark};
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 1.5;
    &:hover {
        color: ${({ theme }) => theme.color.primary.main};
    }
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${mq('xxs')} {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
    ${mq('sm')} {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('lg')} {
      font-size: 2.1rem;
    }
`;

const LogoImg = styled(Img)`
  width: 80px;
  height: 80px;
  ${mq('xxs')} {
    width: 100px;
    height: 100px;
  }
`;

const Links: LangLinkType = {
  "fr-be": [
    {
      label: 'Accueil',
      path: '/fr-be'
    },
    {
      label: 'Catalogue',
      path: '/fr-be/catalogue'
    },
    {
      label: 'Dépannage Réparation',
      path: '/fr-be/depannage'
    },
    {
      label: 'Entretien',
      path: '/fr-be/entretien'
    },
    {
      label: 'Galerie',
      path: '/fr-be/galerie'
    },
    {
      label: 'À propos',
      path: '/fr-be/a-propos'
    },
    {
      label: 'Devis',
      path: '/fr-be/devis'
    },
    {
      label: 'Contact',
      path: '/fr-be/contact'
    },
  ],
  "en-gb": [
    {
      label: 'Home',
      path: '/en-gb'
    },
    {
      label: 'Catalog',
      path: '/en-gb/catalog'
    },
    {
      label: 'Troubleshooting',
      path: '/en-gb/troubleshooting'
    },
    {
      label: 'Maintenance',
      path: '/en-gb/maintenance'
    },
    {
      label: 'Gallery',
      path: '/en-gb/gallery'
    },
    {
      label: 'About',
      path: '/en-gb/about'
    },
    {
      label: 'Quotes',
      path: '/en-gb/quotes'
    },
    {
      label: 'Contact',
      path: '/en-gb/contact'
    },
  ],
  "nl-be": [
    {
      label: 'Ontvangst',
      path: '/nl-be'
    },
    {
      label: 'Catalogus',
      path: '/nl-be/catalogus'
    },
    {
      label: 'Probleemoplossen',
      path: '/nl-be/probleemoplossen'
    },
    {
      label: 'Onderhoud',
      path: '/nl-be/onderhoud'
    },
    {
      label: 'Galerij',
      path: '/nl-be/galerij'
    },
    {
      label: 'Over ons',
      path: '/nl-be/over-ons'
    },
    {
      label: 'Offertes',
      path: '/nl-be/offertes'
    },
    {
      label: 'Contact',
      path: '/nl-be/contact'
    },
  ]
}


export const MobileNav = ({ links, selectLang, isHome }: MobileNavProps): JSX.Element => {
    const [openNav, setOpenNav] = useState<boolean>(false);
    const { color } = useTheme();
    const logo = useStaticQuery(query);
    const { lang } = selectLang;
    const navlinks = Links[lang];
    const handleOpen = () => {
        setOpenNav(!openNav);
    };
    return (
        <MobileNavRoot>
            <NavMenu css={openNav && openedMenu}>
                <LogoLink to="/">
                    <LogoImg image={logo?.logoMobile?.childImageSharp} />
                </LogoLink>
                <SelectLanguage selectLang={selectLang} isHome={isHome} />
                <LinksContainer>
                    {navlinks?.map((link, i) => (
                        <NavLink key={i} to={`${link?.path}`} activeStyle={{ color: `${color.primary.main}` }}>
                            {link?.label}
                        </NavLink>
                    ))}
                </LinksContainer>
            </NavMenu>
            <NavTrigger
                onClick={handleOpen}
                variant="fab"
                color="primary"
                css={openNav ? closeFab : openFab}
            >
                <HamburgerStyled opened={openNav} />
            </NavTrigger>
        </MobileNavRoot>
    );
};

const query = graphql`
  query LogoMobile {
  logoMobile: file(relativePath: {eq: "Topservices24-Logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
`;
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Cookies } from 'react-cookie-consent';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CookiesBanner } from '@/components/atoms';
import { Navigation, Footer, MobileNav, DesktopNav } from '@/components/organisms';

import mq from '@/styles/mq';


interface Props {
  selectLang: {
    lang: string;
    uid: string;
    alternate_languages: ({
      lang: string;
      uid: string;
    }[]) | undefined;
  };
  isHome?: boolean;
  children: React.ReactNode;
}

const Root = styled.div`
  *{
    font-family: Lato, sans-serif;
  }
`;

const Main = styled.main`
  min-height: 100vh;
  margin-top: ${({ theme }) => theme.spacing(0)};
  ${mq('md')} {
    margin-top: ${({ theme }) => theme.spacing(12)};
  }
  ${mq('xl')} {
    margin-top: ${({ theme }) => theme.spacing(14)};
  }
`;

const Layout = ({ isHome = false, selectLang, children }: Props): JSX.Element => {
  const { lang } = selectLang;
  const cookie = Cookies.get('isConsent');

  return (
    <Root>
      <DesktopNav isHome={isHome} selectLang={selectLang} />
      <MobileNav isHome={isHome} selectLang={selectLang} />
      {/* <Navigation selectLang={selectLang} isHome={isHome} /> */}
      <Main role="main">{children}</Main>
      <Footer lang={lang} />
      {!cookie && <CookiesBanner lang={lang} />}
    </Root>
  );
};

export default Layout
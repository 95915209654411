import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
//@ts-ignore
import AniLink from 'gatsby-plugin-transition-link/AniLink';
//@ts-ignore
import TransitionLink from 'gatsby-plugin-transition-link';

export const Link = forwardRef((
  { to, target, type = 'aniLink', children, ...rest }: GatsbyLinkProps<Record<string, unknown>>,
  ref: React.LegacyRef<HTMLAnchorElement>,
): JSX.Element => {
  // If no url
  if (!to) return <div />;

  // If internal
  return (
    <>
      {type === 'link' && (
        //@ts-ignore
        <GatsbyLink ref={ref} to={to} target={target} {...rest}>
          {children}
        </GatsbyLink>
      )}
      {type === 'aniLink' && (
        <AniLink ref={ref} cover direction="left" bg="rgba(240, 240, 240, 1)" to={to} duration={1.5} {...rest}>
          {children}
        </AniLink>
      )}
    </>
  );
});


import React from 'react';
import { RecoilRoot } from 'recoil';
import { Global, ThemeProvider } from '@emotion/react';
import reset from '@/styles/reset';
import theme from '@/styles/theme';

// import 'react-image-lightbox/style.css';

export const Root = (element: HTMLElement): JSX.Element => {
  return (
    <RecoilRoot>
      <Global styles={reset} />
      <ThemeProvider theme={theme}>{element.children}</ThemeProvider>
    </RecoilRoot>
  );
};

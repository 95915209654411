import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ImageDataType from '@/types/data/image';

interface Props {
    image: ImageDataType;
}

export const Img = ({ image, ...props }: Props): JSX.Element => {
    const src = getImage(image.gatsbyImageData);
    return (
        //@ts-ignore
        <GatsbyImage image={src} alt={image?.alt} {...props} />
    )
};

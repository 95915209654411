import React, { ForwardedRef } from 'react';
import styled from '@emotion/styled';

import { Label } from '@/components/atoms/Form/Label';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   Set a label associated with the input
   */
  label?: string;
  name: string;
  star?: boolean;
  /**
   The number of rows to display
   */
  rows?: number;
}

const TextAreaRoot = styled.textarea`
  padding: ${({ theme }) => theme.spacing(1)};
  border: none;
  border-bottom: 0.5px solid ${({ theme }) => theme.color.textPrimary.light};
  background-color: transparent;
  width: 100%;
  resize: vertical;
  outline: none !important;
  box-shadow: none;
  transition: .3s;
  color: ${({ theme }) => theme.color.black.main};
  -webkit-text-fill-color: ${({ theme }) => theme.color.black.main} !important;
  &::placeholder {
    color: ${({ theme }) => theme.color.black.main};
    font-size: 1.5rem;
    font-weight: 500;
  }
  &:focus {
    border-bottom: 0.5px solid ${({ theme }) => theme.color.primary.main};
  }
`;

const InputLabel = styled(Label)`
  color: ${({ theme }) => theme.color.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const Star = styled.span`
  color: ${({ theme }) => theme.color.error.main}; 
`;
/**
 <h3>Display a textarea</h3>
 **/
export const Textarea = React.forwardRef((
  { label, rows = 10, name, star = false, ...props }: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element => {
  return (
    <>
      {label && <InputLabel htmlFor={name}>{label} {star && <Star>*</Star>}</InputLabel>}
      <TextAreaRoot ref={ref} id={name} name={name} rows={rows} {...props} />
    </>
  );
});

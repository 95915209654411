import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';

import mq from '@/styles/mq';

import styled from '@emotion/styled';


mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_KEY}`;


interface MapBoxProps {
    coord: number[];
}

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .map-container {
    width: 100%;
    height: 350px;
  }

  ${mq('sm')}{
    .map-container {
        height: 350px;
    }
  }
  ${mq('lg')}{  
    .map-container {
        height: 500px;
    }
  }
`;

const MapBoxContainer = styled.div`
    width: 100%;
`;


export const Mapbox = ({ coord }: MapBoxProps): JSX.Element => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [zoom] = useState(12);
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current as HTMLElement | string,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [coord[0], coord[1]],
            zoom: zoom,
            minZoom: 7,
            maxZoom: 17,
        });
        new mapboxgl.Marker().setLngLat([coord[0], coord[1]]).addTo(map);
        map.addControl(new mapboxgl.NavigationControl());
        return () => map.remove();
    }, [coord]);

    return (
        <Root>
            <MapBoxContainer>
                <div className="map-container" ref={mapContainer} />
            </MapBoxContainer>
        </Root>
    );
};


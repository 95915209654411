import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface SEOProps {
  description?: string;
  lang?: string;
  title: string;
  url: string;
  alternate?: {
    lang: string;
    url: string;
  }[];
}

const query = graphql`
  query Meta {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export const SEO = ({ 
  description,
  lang = `fr`,
  title,
  url,
  alternate 
}: SEOProps): JSX.Element => {
  const { site } = useStaticQuery(query);


  // TODO: change function
  const renderLinkAlternate = () => {
    if (alternate !== undefined) {
      return alternate?.map(data => (
        <link rel="alternate" hrefLang={data.lang} href={`https://www.topservices24.be/${data.url}`} data-react-helmet="true" />
      ))
    }
    return;
  }
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://www.topservices24.be/${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.topservices24.be" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:site_name" content="Topservices24" />
      <link rel="alternate" hrefLang="x-default" href="https://www.topservices24.be" data-react-helmet="true" />
      <link href='https://api.mapbox.com/mapbox-gl-js/v2.6.0/mapbox-gl.css' rel='stylesheet' />
      {renderLinkAlternate()}
     
    </Helmet>
  );
};

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Typography, Link } from '@/components/atoms';
import PrismicComponent from '@/prismic/prismicComponent';
import useImage from '@/hooks/useImages';
import mq from '@/styles/mq';

interface FooterProps {
  lang: string;
}

interface LinksType {
  [key: string]: string;
}

interface StaticQuery {
  cookies: {
    edges: {
      node: {
        id: string;
        lang: string;
        uid: string;
      }
    }[];
  }
  mentions: {
    edges: {
      node: {
        id: string;
        lang: string;
        uid: string;
      }
    }[];
  }
  footer: {
    edges: {
      node: {
        lang: string;
        data: {
          content: {
            raw: RichTextBlock[];
          };
          socials: {
            social: string;
            social_link: {
              url: string;
            }
          }[];
        }
      }
    }[];
  }
}

const Root = styled.footer`
  background-color: ${({ theme }) => theme.color.primary.dark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 300px;
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${mq('md')} {
    flex-direction: row;
  }
`;

const LeftSection = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  ${mq('xxs')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('sm')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('md')} {
    width: 50%;
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-left: ${({ theme }) => theme.spacing(3)};
  }

  ${mq('xl')} {
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-left: ${({ theme }) => theme.spacing(3)};
  }
`;

const RightSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  ${mq('xxs')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('sm')} {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('md')} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 50%;
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }
  ${mq('xl')} {
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }
`;

const SocialsContainer = styled.div``;

const LegalLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

const SiteInfos = styled.div`
  width: 100%;
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const FooterContent = styled(PrismicComponent)`
  h1, h2, h3, h4, h5 {
    color: ${({ theme }) => theme.color.white.light};
  }
  p, span {
    color: ${({ theme }) => theme.color.white.main};
  }
`;

const LegalLink = styled(Link)`
  transition: .3s;
  color: ${({ theme }) => theme.color.white.light};
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const SocialLink = styled(Typography)``;

const MentionText: LinksType = {
  'fr-be': 'Mentions Légales',
  'en-gb': 'Legal Notice',
  'nl-be': 'Juridische kennisgeving'
}

const CookiesText: LinksType = {
  'fr-be': 'Cookies',
  'en-gb': 'Cookies',
  'nl-be': 'Cookies'
}


export const Footer = ({ lang }: FooterProps): JSX.Element => {
  const data = useStaticQuery<StaticQuery>(query);
  const [footer] = data?.footer?.edges?.filter(data => data?.node?.lang === lang);
  const socials = useImage();
  const cookiesTextLang = CookiesText[lang];
  const mentionTextLang = MentionText[lang];

  const [filterCookieLink] = data?.cookies?.edges?.filter(cookie => cookie?.node?.lang === lang);
  const [filterMentionLink] = data?.mentions?.edges?.filter(mention => mention?.node?.lang === lang);
  const mentionLink = `${filterMentionLink?.node?.lang}/${filterMentionLink?.node?.uid}`;
  const cookieLink = `${filterCookieLink?.node?.lang}/${filterCookieLink?.node?.uid}`;

  return (
    <Root>
      <FooterTop>
        <LeftSection>
          <FooterContent content={footer?.node?.data?.content?.raw} />
        </LeftSection>
        <RightSection>
          <SocialsContainer>
            {footer?.node?.data?.socials?.map(social => (
              <a href={social?.social_link?.url} target="_blank" rel="noopener noreferrer">
                <SocialLink variant="textSm">
                  {socials(social?.social.toLowerCase(), '35px', '35px')}
                </SocialLink>
              </a>
            ))}
          </SocialsContainer>
          <LegalLinksContainer>
            <LegalLink to={`/${cookieLink}`}>
              {cookiesTextLang}
            </LegalLink>
            <LegalLink to={`/${mentionLink}`}>
              {mentionTextLang}
            </LegalLink>
          </LegalLinksContainer>
        </RightSection>
      </FooterTop>
      <SiteInfos>
        <Typography variant="textSm" color="white">© Top services {new Date().getFullYear()}</Typography>
      </SiteInfos>
    </Root>
  );
};

const query = graphql`
  query Footer {
    cookies: allPrismicCookiesPage {
      edges {
        node {
          id
          lang
          uid
        }
      }
    }
    mentions: allPrismicMentionsPage {
      edges {
        node {
          id
          lang
          uid
        }
      }
    }
    footer: allPrismicFooter {
      edges {
        node {
          lang
          data {
            content {
              raw
            }
            socials {
              social
              social_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

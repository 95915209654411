import { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

interface DocType {
  type: string;
  uid: string;
  lang: string;
}

interface StaticQuery {
  catalogue: {
    edges: {
      node: {
        lang: string;
        uid: string;
      }
    }[];
  }
  categories: {
    edges: {
      node: {
        lang: string;
        uid: string;
        data: {
          products: {
            product: {
              document: {
                id: string;
                lang: string;
                uid: string;
              }
            }
          }[];
        }
      }
    }[];
  }
}

// @ts-ignore
// const linkResolver = (doc) => {
//   console.log(doc, 'DOOOCCCC');
//   switch (doc.type) {
//     case `home_page`:
//       return `/${doc.lang}`;
//     case `catalogue_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `contact_p`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `devis_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `galerie_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `entretien_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `depannage_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     case `apropos_page`:
//       return `/${doc.lang}/${doc.uid}`;
//     default:
//       return `/`;
//   }
// };

// module.exports = linkResolver;

const useLinkResolver = () => {
  const data = useStaticQuery<StaticQuery>(query);

  const { catalogue, categories } = data;

  const match = useCallback(
    (doc: DocType) => {
      if(doc?.type === `ca`) {
        const [filteredCatalogue] = catalogue?.edges?.filter(data => data?.node?.lang === doc?.lang);
        const [filteredCategory] = categories?.edges?.filter(data => data?.node?.lang === doc?.lang);

        return `/${doc?.lang}/${filteredCatalogue?.node?.uid}/${filteredCategory?.node?.uid}`
      }
      if (doc?.type === `product`) {
        const [filteredCatalogue] = catalogue?.edges?.filter(
          (data) => data?.node?.lang === doc?.lang,
        );
        let productUrl;
        categories?.edges?.map(category => category?.node?.data?.products?.map(product => {
          if(product?.product?.document?.lang === doc?.lang && product?.product?.document?.uid === doc?.uid){
            productUrl = `/${doc?.lang}/${filteredCatalogue?.node?.uid}/${category?.node?.uid}/${product?.product?.document?.uid}`;
          }
        }))
        return productUrl;
      }

      return `/${doc?.lang}/${doc?.uid}`;
    },
    [],
  );
  return (doc: DocType) => match(doc);
};


export default useLinkResolver;

const query = graphql`
  query resolver {
    catalogue: allPrismicCataloguePage {
      edges {
        node {
          lang
          uid
        }
      }
    }
    categories: allPrismicCa {
      edges {
        node {
          lang
          uid
          data {
            products {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    lang
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import cookies from 'js-cookie';
import { Link } from '@/components/atoms';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react"

import useImages from '@/hooks/useImages';

interface Props {
    isHome?: boolean;
    selectLang: {
        lang: string;
        uid: string;
        alternate_languages: {
            lang: string;
            uid: string;
        }[] | undefined;
    };
}


export const SelectLanguage = ({ selectLang, isHome }: Props): JSX.Element => {
    const flags = useImages();

    const { lang, alternate_languages } = selectLang;
    useEffect(() => {
        cookies.set('favorite_language', lang);
    }, [lang])
    return (
        <Menu>
            <MenuButton as="button" css={css`
                align-self: center;
                outline: none;
                background-color: transparent;
            `}>
                {flags(lang, '25px', '25px')}
            </MenuButton>
            <MenuList css={css`min-width: 6%;`}>
                {alternate_languages?.map((data, i) => {
                    return (
                        <Link to={isHome ? `/${data?.lang}` : `/${data?.lang}/${data?.uid}`} key={i}>
                            <MenuItem css={css`padding: 4px; background-color: rgba(250, 250, 250, 0.7);`} >{flags(data?.lang as string, '25px', '25px')}</MenuItem>
                        </Link>
                    )
                })}
            </MenuList>
        </Menu>
    );
};


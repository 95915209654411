import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Img } from '@/components/atoms';

import { css } from '@emotion/react';
import mq from '@/styles/mq';

const useImages = () => {
  const data = useStaticQuery(query);

  return (lang: string, width = '25px', height = '25px') => {
    switch (lang) {
      case 'fr-be':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.fr?.childImageSharp}
          />
        );
      case 'en-gb':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.en?.childImageSharp}
          />
        );
      case 'nl-be':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.nl?.childImageSharp}
          />
        );
      case 'facebook':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.facebook?.childImageSharp}
          />
        );
      case 'instagram':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.instagram?.childImageSharp}
          />
        );
      case 'twitter':
        return (
          <Img
            css={css`
              margin: 0 5px;
              width: ${width};
              height: ${height};
            `}
            image={data?.twitter?.childImageSharp}
          />
        );
      default:
        return;
    }
  };
};

export default useImages;

const query = graphql`
  query {
    fr: file(relativePath: { eq: "lang/fr.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    en: file(relativePath: { eq: "lang/en.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    nl: file(relativePath: { eq: "lang/nl.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
